<template>
    <headers></headers>
    <div class="top-gap"></div>
    <div class="stack">
        <div>
            <b>{{ $t('lp.title') }}</b>
            <span>{{ $t('lp.title1') }}</span>
        </div>
    </div>
    <div class="my-lp" style="margin-bottom: 50px;">
        <!-- <div class="my-title">{{ $t('lp.stackList1') }}</div> -->
        <div class="tb max-width">
            <div>
                <div>
                    <img src="../../static/images/lp/my-lp.png" alt="">
                    {{ $t('lp.stackList1') }}
                </div>
                <span>{{ Util.showAmount(stake.staking) }} USDT</span>
            </div>
            <div>
                <div>
                    <img src="../../static/images/lp/yet.png" alt="">
                    {{ $t('lp.earn') }}
                </div>
                <span>{{ Util.showAmount(stake.interest) }} USDT</span>
            </div>
        </div>
    </div>
    <!-- 主体 -->
    <div class="content max-width">
        <div v-for="(item,index) in list" :key="index">
            <div class="days">
                <img src="../../static/images/lp/usdt.png" alt="">
                <div class="right">
                    <b>STAKOS</b>
                    <p class="day">Stake {{ item.days }}</p>
                    <p>{{ item.earnInfo }}</p>
                </div>
            </div>
            <p class="apr">APR</p>
            <div class="amount">
                <div>
                    <div>
                        <div>{{ $t('lp.underway') }}</div>
                        <b><numberGrow :time="10" :type="6" :value="item.staking" ></numberGrow> USDT</b>
                    </div>
                    <button class="redeem-but" v-if="item.staking - 0 > 0" @click="goRecord(item.id)">{{ $t('lp.redeem') }}</button>
                    <button class='disabled-but' v-else>{{ $t('lp.redeem') }}</button>
                </div>
                <div>
                    <div>
                        <div>{{ $t('lp.earn') }}</div>
                        <b><numberGrow :time="10" :type="6" :value="item.earned" ></numberGrow> USDT</b>
                    </div>
                </div>
                <div>
                    <div>
                        <div>{{ $t('lp.yet') }}</div>
                        <b><numberGrow :time="10" :type="6" :value="item.available" ></numberGrow> USDT</b>
                    </div>
                    <button class="yet-but" v-if="item.available - 0 > 0" @click="toWithdrawInterest(item.id)">{{ $t('lp.yetBut') }}</button>
                    <button class='disabled-but' v-else>{{ $t('lp.yetBut') }}</button>
                </div>
            </div>
             <!-- 质押按钮 -->
             <div class="lp-buts">
                <button class="lp-but disabled-but" v-if="wStore.curAccount && currentMenu.quota - 0 <= 0">{{ $t('lp.lp') }}</button>
                <button class="lp-but accredit" @click="approveUSDT" v-else-if="wStore.curAccount  && (wallet.usdtAllowance - 0 <= 0 || wallet.usdtAllowance-item.quota < 0)">{{ $t('lp.accredit') }}</button>
                <button class="lp-but" v-else @click="toStake(item.id)">{{ $t('lp.lp') }}</button>
            </div>
        </div>
    </div>
    <record ref="childComponent" :type="currentType"></record>
    <div class="dialog" v-if="showStake">
        <div>
            <div class="title">
                {{ $t('lp.lp') }}
                STAKOS {{list[curID - 1].days}}
                <img @click="showStake=false" src="../../static/images/close.png" />
            </div>
            <div class="usable">
                <div>
                    <img src="../../static/images/lp/usdt.png" />
                    USDT
                </div>
                <div>
                    <b>{{Util.showAmount(list[curID - 1].quota)}} USDT</b>&nbsp;{{ $t('lp.usable') }}
                </div>
            </div>
          <div class="usable">
                {{$t('lp.quotaRemain')}} :
                {{Util.showAmount(wallet.usdtBalance)}} USDT
                <div class="max" @click="showMax">{{ $t('lp.max') }}</div>
            </div>
            <input placeholder="0.0000" v-model="inputStakeAmount"/>
            <button class="disabled" v-if="list[curID - 1].quota - 0 <= 0 || wallet.usdtBalance - 0 <= 0">{{ $t('lp.lp') }}</button>
            <button class="accredit" @click="approveUSDT" v-else-if="wallet.usdtAllowance - 0 <= 0 || wallet.usdtAllowance - list[curID - 1].quota < 0">{{ $t('lp.accredit') }}</button>
            <button v-else @click="stakeUSDT">{{ $t('lp.lp') }}</button>
        </div>
    </div>
<bases></bases>
</template>
<script setup>
    import headers from '@/components/header'
    import bases from '@/components/base'
    import record from './record.vue'
    import numberGrow from '@/components/numberGanttyNum'
    import {computed, onMounted, reactive, ref, watch} from 'vue';

    import {queryCompound, queryInfo, withdrawInterest, withdrawTopBonus} from '@/api/lp'
    import Util from '@/utils/common-util'

    import {memberStore} from "@/store/member"
    import {walletStore} from "@/store/wallet"
    import {webStore} from "@/store/web"
    import i18n from "@/i18n";
    import {ElMessage} from "element-plus";
    import {useRouter} from "vue-router";
    import {call, getContractAddress, send} from "@/contract/web3-util";
    import Web3 from "web3";
    import {end, start} from "@/api/loading";

    // 質押彈窗的顯示
    const showStake = ref(false);

    // 質押彈窗輸入框
    const inputStakeAmount = ref('');

    const mStore = memberStore()
    const wStore = walletStore()
    const wbStore = webStore()

    const router = new useRouter()

    const list = reactive([
        {
          id: 1,
          days: 0,
          stakeInfo: 'Stake 0 Days',
          earnInfo: 'Stake USDT - Earn USDT',
          arp: '219.00%',
          min: 0,
          quota: 0,
          staking: 0,
          earned: 0,
          available: 0
        },
        {
          id: 2,
          days: 30,
          stakeInfo: 'Stake 30 Days',
          earnInfo: 'Stake USDT - Earn USDT',
          arp: '328.50%',
          min: 0,
          quota: 0,
          staking: 0,
          earned: 0,
          available: 0
        },
        {
          id: 3,
          days: 90,
          stakeInfo: 'Stake 90 Days',
          earnInfo: 'Stake USDT - Earn USDT',
          arp: '474.50%',
          min: 0,
          quota: 0,
          staking: 0,
          earned: 0,
          available: 0
        }
    ])
    const menuIndex = ref(0);
    // 切换菜单
    const getMenu = (index) => {
        menuIndex.value = index;
    }
    const currentMenu = computed(() => {
        return list[menuIndex.value]
    })


    const stake = reactive({
      staking: 0,
      interest: 0,
      compound: 0
    })

    const compound = reactive({
      state: false,
      bonus: 0,
      available: 0
    })

    const wallet = reactive({
      usdtBalance: 0,
      usdtAllowance: 0
    })

    const curID = ref(1)

    const infoInterval = ref('')

    const goPage = (path) => {
        router.push({
            path
        })
    }

    function getCompound() {
      if (wbStore.isLogin()) {
        queryCompound().then(result => {
          if (Util.isDefine(result) && result.data.code === 1) {
            compound.state = result.data.data.compoundState
            compound.bonus = result.data.data.compoundTopTotal
            compound.available = result.data.data.compoundTopRemain
          }
        })
      }
    }

    async function getStakeTypeInfos() {
      stake.staking = 0
      if (wStore.curAccount) {
        const results = await call('stake-v2', 'getStakeInfos', [wStore.curAccount, [1, 2, 3]])
        if (Util.isDefine(results)) {
          for (let i = 0 ; i < results.length ; i++) {
            list[i].staking = Web3.utils.fromWei(results[i].stakeAmount) - 0
            list[i].min = Web3.utils.fromWei(results[i].minStake) - 0
            list[i].quota = Web3.utils.fromWei(results[i].quotaRemain) - 0

            stake.staking += list[i].staking
          }
        }

        queryInfo().then(result => {
          if (Util.isDefine(result) && result.data.code === 1) {
            const data = result.data.data.stakeInfo
            stake.compound = data.compoundTopTotal - 0
            stake.interest = data.interest1Remain - 0 + data.interest2Remain + data.interest3Remain + data.interest4Remain

            list[0].earned = data.interest1Total - 0
            list[0].available = data.interest1Remain - 0

            list[1].earned = data.interest2Total - 0
            list[1].available = data.interest2Remain - 0

            list[2].earned = data.interest3Total - 0
            list[2].available = data.interest3Remain - 0
          }
        })
      }
    }

    const getStakeInfosTimer = () => {
      getStakeTypeInfos()
      clearInterval(infoInterval.value)
      infoInterval.value = setInterval(function () {
        getStakeTypeInfos()
      },20000)
    }

    const getWalletUSDTInfo = async() => {
      if (wStore.curAccount) {
        wallet.usdtBalance = Web3.utils.fromWei(await call('usdt', 'balanceOf', [wStore.curAccount])) - 0
        wallet.usdtAllowance = Web3.utils.fromWei(await call('usdt', 'allowance', [wStore.curAccount, getContractAddress('stake-v2')])) - 0
      }
    }

    const approveUSDT = () => {
      if (!wStore.curAccount) {
        ElMessage({
          message: i18n.global.t('alert.connectWallet'),
          type: 'warning'
        })
        return
      }

      start()
      send('usdt', 'approve', [getContractAddress('stake-v2'), '115792089237316195423570985008687907853269984665640564039457584007913129639935'])
          .then((receipt) => {
            if (receipt.status) {
              ElMessage({
                message: i18n.global.t('alert.success'),
                type: 'success'
              })

              wallet.usdtAllowance = Web3.utils.fromWei('115792089237316195423570985008687907853269984665640564039457584007913129639935', 'ether') - 0
            } else {
              ElMessage({
                message: i18n.global.t('alert.failed'),
                type: 'error'
              })
            }
            end()
          }).catch(function(error) {
        console.error(error)
        end()
      })
    }

    function toStake(id) {
      if (!wbStore.isLogin()) {
        ElMessage({
          message: i18n.global.t('alert.connectWallet'),
          type: 'warning'
        })
        return
      }
      curID.value = id
      showStake.value = true

      getWalletUSDTInfo()
    }

    function showMax() {
      const quota = list[curID.value - 1].quota
      const balance = wallet.usdtBalance
      const max = quota - balance > 0 ? balance : quota

      if (max - 0 > 0) {
        inputStakeAmount.value = max.toFixed(4)
      }
    }

    const stakeUSDT = () => {
      if (!wStore.curAccount) {
        ElMessage({
          message: i18n.global.t('alert.connectWallet'),
          type: 'warning'
        })
        return
      }

      if (mStore.memberInfo.uid - 0 === 0) {
        router.push({path: '/user'})
        ElMessage({
          message: i18n.global.t('alert.notBound'),
          type: 'warning'
        })

        return
      }

      if (!/^((0\.0*[1-9]+[0-9]*)|([1-9]+[0-9]*\.[0-9]*[0-9])|([1-9]+[0-9]*))$/.test(inputStakeAmount.value)) {
        ElMessage({
          message: i18n.global.t('alert.amountError'),
          type: 'warning'
        })

        return
      }

      if (inputStakeAmount.value - 0 < list[curID.value - 1].min) {
        ElMessage({
          message: i18n.global.t('alert.minAmountError') + ' ' + Util.numFormat4Floor(list[curID.value - 1].min) + ' USDT',
          type: 'warning'
        })

        return
      }

      if (inputStakeAmount.value - 0 > list[curID.value - 1].quota - 0) {
        ElMessage({
          message: i18n.global.t('alert.quotaInsufficient'),
          type: 'warning'
        })

        return
      }

      if (inputStakeAmount.value - 0 > wallet.usdtBalance) {
        ElMessage({
          message: i18n.global.t('alert.insufficient'),
          type: 'warning'
        })

        return
      }

      if (inputStakeAmount.value - 0 > wallet.usdtAllowance) {
        return
      }

      start()
      send('stake-v2', 'stake', [curID.value, Web3.utils.toWei(inputStakeAmount.value + '')]).then(receipt => {
        end()
        if (receipt.status) {
          ElMessage({
            message: i18n.global.t('alert.success'),
            type: 'success'
          })

          inputStakeAmount.value = ''
          showStake.value = false

          getStakeTypeInfos()

        } else {
          ElMessage({
            message: i18n.global.t('alert.failed'),
            type: 'error'
          })
        }
      }).catch(function(error) {
        console.error(error)
        ElMessage({
          message: i18n.global.t('alert.failed'),
          type: 'error'
        })
        end()
      })
    }

    const toWithdrawInterest = async (stakeType) => {
      if (wbStore.isLogin()) {
        if (list[stakeType - 1].interestRemain - 0 <= 0) {
          return
        }

        start()
        withdrawInterest({stakeType: stakeType}).then(result => {
          if (Util.isDefine(result) && result.data.code === 1) {
            const data = result.data.data

            const orderSerial = data.orderSerial
            const stakeType = data.stakeType
            const amount = data.amount
            const signTime = data.signTime
            const sign = data.sign

            list[stakeType - 1].interestRemain -= amount

            send('stake-v2', 'withdrawInterest', [stakeType, Web3.utils.toWei(amount + ''), signTime, orderSerial, sign]).then(receipt => {
              if (receipt.status) {
                ElMessage({
                  message: i18n.global.t('alert.success'),
                  type: 'success'
                })

                getWalletUSDTInfo()
                getStakeTypeInfos()
              } else {
                ElMessage({
                  message: i18n.global.t('alert.failed'),
                  type: 'error'
                })
              }
              end()
            }).catch(function(error) {
              console.error(error)
              end()
            })
          }
        }).catch(function(error) {
          console.error(error)
          end()
        })
      }
    }

    const toWithdrawTopBonus = async (stakeType) => {
      if (wbStore.isLogin()) {
        if (compound.available - 0 <= 0) {
          return
        }

        start()
        withdrawTopBonus().then(result => {
          if (Util.isDefine(result) && result.data.code === 1) {
            const data = result.data.data

            const orderSerial = data.orderSerial
            const amount = data.amount
            const signTime = data.signTime
            const sign = data.sign

            compound.available -= amount

            send('stake-v2', 'withdrawTopBonus', [Web3.utils.toWei(amount + ''), signTime, orderSerial, sign]).then(receipt => {
              if (receipt.status) {
                ElMessage({
                  message: i18n.global.t('alert.success'),
                  type: 'success'
                })
              } else {
                ElMessage({
                  message: i18n.global.t('alert.failed'),
                  type: 'error'
                })
              }
              end()
            }).catch(function(error) {
              console.error(error)
              end()
            })
          }
        }).catch(function(error) {
          console.error(error)
          end()
        })
      }
    }

    let currentType = ref(1)
    let childComponent = ref(null)
    const goRecord = (type) => {
        if(type === currentType.value){
            currentType.value = currentType.value == 1?2:1
            setTimeout(() => {
                currentType.value = type
            }, 20);
        }else{
            currentType.value = type;
        }
    }

    onMounted(() => {
      // getCompound()
      getWalletUSDTInfo()
      getStakeInfosTimer()
    })

    const typeIndex = ref(1)
    const getType = (index) => {
        typeIndex.value = index
    //   getStakeList()
    }
    const stakes = reactive({
        page: 1,
        pageSize: 0,
        totalPage: 0,
        totalRecord: 0,
        list: [],
    })


    const curAccount = computed(()=>{
      return wStore.curAccount
    })
    watch(curAccount, (newVal, oldVal) =>{
      compound.state = false
      compound.bonus = 0
      compound.available = 0

      stake.staking = 0
      stake.interest = 0
      stake.compound = 0

      if (newVal) {
        if (oldVal) {
          alert(i18n.global.t('alert.reLogin'))
        }

        wbStore.accountLogin().then((result) => {
          wbStore.setLoginState(true)
          mStore.queryMemberInfo()

        //   getCompound()
          getWalletUSDTInfo()
          getStakeInfosTimer()

        }).catch((reason) => {
          wbStore.setLoginState(false)
          console.error('login failed')
        })
      }
    })

</script>
<style scoped src="./css/index.less" lang="less">
</style>
